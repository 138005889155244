import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClassessAsyncThunk,
  getStaffClassessAsyncThunk,
} from "../../redux/pagesSlices/classesSlice";
import { handleModel } from "../../redux/layoutSlices/modelSlice";
import { setClass } from "../../redux/pagesSlices/authSlice";
import { parseUrlFromModel } from "../../helpers/asset";
import { useSearchParams } from "react-router-dom";
import { ApiRequests } from "../../service/ApiRequests";
import { RiArrowDropDownFill } from "react-icons/ri";
import { redirect_uris } from "../../constants";
import { toast } from "react-toastify";

function SwitchSpaces() {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.classes.Classes);
  const selectedSpace = useSelector((state) => state.auth.class);
  const memberships = useSelector((state) => state.auth.memberships);
  const classLoading = useSelector((state) => state.classes.loading);
  const [searchParams, setSearchParams] = useSearchParams();
  const spaceId = searchParams.get("space_id");
  const isToastShown = useRef(false);

  useEffect(() => {
    const storedClass = localStorage.getItem("selectedClass");
    const defaultClass = storedClass ? JSON.parse(storedClass) : null;

    if (!selectedSpace && classes.results?.length > 0) {
      const matchedClass = spaceId
        ? classes.results.find((c) => c?.class_id === Number(spaceId))
        : null;

      if (matchedClass) {
        dispatch(setClass(matchedClass));
      } else if (defaultClass?.class_id && memberships) {
        ApiRequests.ValidateClasses({
          class_id: defaultClass.class_id,
          isAdmin: memberships.some((i) => i?.name === "Admin"),
        }).then((res) => {
          dispatch(setClass(res?.data?.hasAccess ? defaultClass : classes.results[0]));
        });
      } else {
        dispatch(setClass(classes.results[0]));
      }
      setSearchParams({});
    }
    let key = setTimeout(() => {
    if (!selectedSpace && !classes?.results?.length && !classLoading?.getClassessAsyncThunk && !classLoading?.getStaffClassessAsyncThunk && !isToastShown.current) {
      toast.error("A Space has not been created yet. Please create a Space first to proceed with the portal.");
      isToastShown.current = true;
      setTimeout(() => {
        window.location.href = redirect_uris?.Admin;
      }, 7000);
    }
  }, 5000);
    return () => key? clearTimeout(key): null;
  }, [classes.results, dispatch, spaceId, selectedSpace, memberships, setSearchParams, classLoading]);

  useEffect(() => {
    const params = {
      limit: 10,
      page: 1,
      populate: "image,user_id",
    };

    const fetchClasses = async () => {
      dispatch(
        memberships?.some((i) => i?.name === "Admin")
          ? getClassessAsyncThunk(params)
          : getStaffClassessAsyncThunk(params)
      );
    };

    fetchClasses();
  }, [dispatch, memberships]);

  const handleStudentData = () => {
    dispatch(
      handleModel({
        model: "ClassModal",
        state: true,
        args: { resource: "Family", args: { student: selectedSpace } },
      })
    );
  };

  useEffect(() => {
    if (selectedSpace) {
      const storedClass = localStorage.getItem("selectedClass");
      if (!storedClass || JSON.stringify(selectedSpace) !== storedClass) {
        localStorage.setItem("selectedClass", JSON.stringify(selectedSpace));
      }
    }
  }, [selectedSpace]);

  return (
    <div className="section-2">
      <div className="classes-icon"></div>
      <div className="class-bar-section" onClick={handleStudentData}>
        <img
          src={parseUrlFromModel(selectedSpace?.image) || require("../../images/logo.png")}
          alt="avatar"
        />
        <p>{selectedSpace ? selectedSpace.name : "Select Space"}</p>
        <span>
          <RiArrowDropDownFill />
        </span>
      </div>
    </div>
  );
}

export default SwitchSpaces;
