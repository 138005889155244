import "./App.scss";
import "./responsive.scss";
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
} from "firebase/auth";
import { auth } from "./config/firebase";
import HeaderLayout from "./layout/headerLayout.jsx";

import React, { useEffect, useState } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { PageLoader } from "./auth0/page-loader";
import Unauthorized from "./components/Unauthorized";
import Highlights from "./screens/Highlights.js";

import Dashboard from "./screens/Dashboard";
import { redirect_uris, space } from "./constants.js";
import { ApiRequests } from "./service/ApiRequests.js";
import { Button } from "react-bootstrap";
import FindOrgs from "./components/FindOrgs.jsx";
import AllProducts from "./screens/AllProducts.js";
import { useDispatch, useSelector } from "react-redux";
import Countly from "./config/countly.js";
import ConsolDashboardPage from "./screens/ConsolDashboardPage.jsx";
import ConsolTablePage from "./screens/ConsolTablePage.jsx";
import ConsolApplicationsPage from "./screens/ConsolApplicationsPage.jsx";
import ConsolLinks from "./screens/ConsolLinks.jsx";
import Intercom from "@intercom/messenger-js-sdk";
import CryptoJS from 'crypto-js'
import { getProfileAsyncThunk, setMembership } from "./redux/pagesSlices/authSlice.js";

function App({ organization }) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const [error, setError] = useState();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [user, setUser] = useState(null);
  console.log("user123", user)
  const auth = getAuth();
  const currentClass = useSelector((state) => state.auth.class); // Adjust this selector based on your Redux state structure
  console.log("🚀 ~ App ~ currentClass:", currentClass)

  const d = useDispatch();
  const userId = user ? user?.user_id?.toString() : "";

  // useEffect(() => {
  //   setRolesLoading(false);
  // }, [ClassLoading]);

  const SECRET_KEY = 'YmAzw4pAfnTcNezrBduV9I1HsPa3PsS9mv3LqV9i';

const userHash = CryptoJS.HmacSHA256(user?.uid, SECRET_KEY).toString(CryptoJS.enc.Hex);
useEffect(() => {
  if (user?.email && user?.uid) {
    Intercom({
      app_id: "k12qxhbg",
      user_id: user?.uid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      user_hash: userHash // IMPORTANT: Replace this with the HMAC code you generated on your server
    });
  }
}, [user]);

  const removeTokenFromURL = () => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Remove the 'token' query parameter
    currentURL.searchParams.delete("token");

    // Replace the current entry in the history with the updated URL
    window.history.replaceState({}, "", currentURL.toString());
  };
  const verifyAuth = async () => {
    const customToken = localStorage.getItem("custom-token");
    const _token = token || customToken;
    if (_token) {
      await auth.signOut();
      localStorage.setItem("custom-token", _token);
      setLoading(true);
      signInWithCustomToken(auth, _token)
        .then((userCredential) => {
          const user = userCredential.user;
          setUser(user);
          setToken(user);
          removeTokenFromURL();
        })
        .catch((error) => {
          window.location.href =
            redirect_uris.Login +
            `signin/?redirect=${window.location.href.split("?")[0]}`;
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleError = (error) => {
    if (error.response) {
      setError(error.response.data);
    } else if (error.request) {
      setError("No response received from the server.");
    } else {
      setError(error.message);
    }
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const oldSpace = JSON.parse(localStorage.getItem("space"));
        const pathArray = window.location.pathname.split("/");
        const consoleValue = pathArray[2];
        if(!organization?.tenantId || !oldSpace?.tenantId) return;
        if (organization?.tenantId !== oldSpace?.tenantId)
          window.location.href = window.location.href.replace(
            consoleValue,
            oldSpace?.displayName
          );
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    verifyAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setToken(user);

        if (
          user &&
          process.env.REACT_APP_COUNTLY_APP_ENABLED === "true" &&
          user.displayName
        ) {
          try {
            Countly.user_details({
              name: user.displayName,
              username: user.uid,
              email: user.email,
              phone: user.phoneNumber,
              picture: user.photoURL,
            });
          } catch (error) {
            console.error("Error setting countly user details:", error);
          }
        }
        //
        try {
          setRolesLoading(true);

          const memberSip = await ApiRequests.getOrganizationMembership(
            organization?.tenantId
          );

          if (
            memberSip &&
            memberSip.data &&
            Array.isArray(memberSip.data) &&
            memberSip.data.length > 0
          ) {
            setRoles(memberSip.data.map((member) => member.name));
            localStorage.setItem(
              "role",
              memberSip.data.map((member) => member.name)
            );
          }
          d(setMembership(memberSip.data));
          // const profile = await ApiRequests.getProfile();
          await d(getProfileAsyncThunk());
        } catch (error) {
          if (!token) {
            window.location.href =
              redirect_uris.Login +
              `signin/?redirect=${window.location.href.split("?")[0]}`;
          }
        } finally {
          setRolesLoading(false);
        }
        //
      } else {
        const customToken = localStorage.getItem("custom-token");
        const _token = token || customToken;
        if (!_token) {
          window.location.href =
            redirect_uris.Login +
            `signin/?redirect=${window.location.href.split("?")[0]}`;
        }
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);
  const setToken = async (user) => {
    try {
      localStorage.setItem("app-access-token-auth0", user.accessToken);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    } catch (error) {
      setLoading(false);
    }
  };

  const buttonStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 999,
  };


  if (!organization) return <FindOrgs />;

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          index
          element={
            <HeaderLayout>
              <Dashboard roles={roles} />
            </HeaderLayout>
          }
        />
        <Route
          path="highlights"
          element={
            <HeaderLayout>
              <Highlights />
            </HeaderLayout>
          }
        />

        <Route
          path="ptsroduc"
          element={
            <HeaderLayout>
              <AllProducts />
            </HeaderLayout>
          }
        />
        <Route path="/consoldashboard" element={<ConsolLinks />} />
        <Route path="/consoltable" element={<ConsolTablePage />} />
        <Route path="/consolapplications" element={<ConsolApplicationsPage />} />

        <Route path="unauthorized" element={<Unauthorized />} />
      </Route>
    ),
    { basename: `/console/${organization?.displayName ?? space}/` }
  );
  if (loading || rolesLoading)
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  if (roles.length === 0) {
    return (
      <div className="error-div">
        <h2>{"403"}</h2>
        <h1>OOPS</h1>
        <h5 className="error-message">
          {"You are not authorized for this tenant."}
          <Button
            variant="link"
            as="a"
            href={redirect_uris.Login + "signup/organization"}
          >
            Go to Organization
          </Button>
        </h5>
      </div>
    );
  }
  return (
    <>
      <RouterProvider key={currentClass?.class_id} router={router} />
    </>
  );
}
const withOrganizationData = (WrappedComponent) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const [organization, setOrganization] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchOrganization = async () => {
        try {
          const pathArray = window.location.pathname.split("/");
          const consoleValue = pathArray[2];
          if (!consoleValue) throw new Error("No console value found");
          const { data } = await ApiRequests.getOrganization(consoleValue);
          localStorage.setItem("space", JSON.stringify(data));
          setOrganization(data);
        } catch (error) {
          localStorage.removeItem("space");
        } finally {
          setLoading(false);
        }
      };

      fetchOrganization();
    }, []);

    if (loading) {
      return (
        <div className="page-layout">
          <PageLoader />
        </div>
      );
    }

    return <WrappedComponent organization={organization} {...props} />;
  };
};
export default withOrganizationData(App);
