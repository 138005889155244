import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import { catchAsync, handleLoadingErrorParamsForAsycThunk, reduxToolKitCaseBuilder } from "../../helpers/detectError";
import { toast } from "react-toastify";
import history from "../../route/history";


// user Login With Credentials
export const getProfileAsyncThunk = createAsyncThunk(
  "user/getProfileAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getProfile();
    return response?.data;
  })
);



const initialState = {
  //news states
  user: null,
  profile: null,

  memberships: [],
  class: null,
  space: null,
  tokens: null,
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const blogSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeUser: (state, action) => {
      state.user = action.payload
    },
    setClass: (state, action) => {
      state.class = action.payload
      localStorage.setItem("selectedClass", JSON.stringify(action.payload));
    },
    setSpace: (state, action) => {
      state.space = action.payload
    },
    setMembership: (state, action) => {
      state.memberships = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsyncThunk.fulfilled, (state, action) => {
        state.profile = action.payload;
      })


      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...(reduxToolKitCaseBuilder([
            getProfileAsyncThunk
          ]))
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default blogSlice.reducer;
export const { setLoading, storeUser, setClass, setSpace, setMembership } = blogSlice.actions;
