import { useState, useEffect } from "react";

const API_URL = `https://${process.env.REACT_APP_NotificationPath}`;

const useNotifications = (organizationId, userId) => {
  const [notifications, setNotifications] = useState([]);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(`${API_URL}/websocket?org=${organizationId}&user=${userId}`);
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "notifications") {
        setNotifications(data.data);
      } else if (data.type === "new_notification") {
        setNotifications((prev) => [data.data, ...prev]);
      } else if (data.type === "update_notification") {
        console.log("🚀 ~ useEffect ~ data.data:", data.data)
        setNotifications((prev) => prev.map((n) => (n.id === data.data.id ? data.data : n)));
      } else if (data.type === "clear_notifications") {
        setNotifications([]);
      }
    };

    ws.onopen = () => console.log("Connected to WebSocket");
    ws.onclose = () => console.log("WebSocket disconnected");

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [organizationId, userId]);

  const sendNotification = async (message) => {
    if (!message.trim()) return;

    await fetch(`${API_URL}/notifications?org=${organizationId}&user=${userId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        organization_id: organizationId,
        message,
        status: 'unread',
        timestamp: new Date().toISOString(),
        // user_id: userId,
      }),
    });
  };
  const readNotification = async (messageId) => {
    if (!messageId) return;
    if (!socket) return;
    socket.send(JSON.stringify({ type: "update_notifications", data: { id: messageId, status: 'read' } }));
  };

  const clearNotifications = async () => {
    await fetch(`${API_URL}/clear`, { method: "DELETE" });
  };

  return { notifications, sendNotification, clearNotifications, readNotification };
};

export default useNotifications;