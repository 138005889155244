import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../../redux/layoutSlices/modelSlice.js";
import {
  getClassessAsyncThunk,
  getStaffClassessAsyncThunk,
} from "../../redux/pagesSlices/classesSlice.js";
import { parseUrlFromModel } from "../../helpers/asset.js";
import { setClass } from "../../redux/pagesSlices/authSlice.js";
import PaginationCompo from "../pagination/PaginationCompo.js";
import Filterinput from "../Filterinput.jsx";

const ClassModal = () => {
  const d = useDispatch();
  const [search, setSearch] = useState();
  const state = useSelector((state) => state.model?.modelState?.ClassModal);
  const memberShip = useSelector((s) => s.auth?.memberships);
  console.log("🚀 ~ ClassModal ~ memberShip:", memberShip)
  const classes = useSelector((s) => s.classes.Classes);
  const selectedSpace = useSelector((s) => s.auth.class);
  const closeModel = () => {
    d(handleModel({ model: "ClassModal", state: false }));
    setSearch("");
  };

  const handleSelectClass = (item) => {
    d(setClass(item));
    closeModel();
  };

  useEffect(() => {
    if (!selectedSpace && classes.results && classes.results.length > 0) {
      d(setClass(classes.results[0]));
    }
  }, [classes.results, d, selectedSpace]);

  useEffect(() => {
    if (state) {
    const params = {
      limit: 10,
      page: 1,
      ...(search ? { name: search } : {}),
    };
    if (memberShip.length) {
      const key = setTimeout(() => {
        d(
          memberShip?.some((i) => i?.name === "Admin")
            ? getClassessAsyncThunk(params)
            : getStaffClassessAsyncThunk(params)
        );
      }, 1000);
      return () => clearTimeout(key);
    }
    }
  }, [search, d, memberShip]);

  const ClassesListView = ({
    data: item,
    handleSelectClass,
    selectedSpace,
  }) => {
    return (
      // .listings-main-wrapper
      <div
        className={`assets-table-body ${
          selectedSpace?.class_id === item?.class_id ? "selected-class" : ""
        }`}
      >
        <div className="row">
          <div className="col-lg-6">
            <div className="table-row gap-2">
              <div
                className="table-data"
                onClick={() => handleSelectClass(item)}
              >
                <img
                  src={
                    parseUrlFromModel(item?.image) ||
                    require("../../images/logo.png")
                  }
                  alt="#"
                />
                <h3>{item.name}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="people-space-modal">
      {" "}
      <Modal
        size="md"
        show={state}
        onHide={closeModel}
        aria-labelledby="example-modal-sizes-title-lg"
        className="space-listing-modal space-class-listings-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="link-student-filter gap-4 mb-2">
              <Filterinput
                handleSearch={(e) => setSearch(e.target.value)}
                placeholder="Filter space"
                widthStyle="true"
              />
            </div>
          </div>
          <div
            className="main-screen-content-wrapper"
            style={{ height: "auto", overflow: "auto", padding: "0" }}
          >
            <div className="people-body">
              <div className="people-content">
                <div className="container-fluid">
                  <div className="people-header">
                    <h1 style={{ fontSize: "14px" }}>Name</h1>
                  </div>
                  <div className="listings-main-wrapper">
                    <PaginationCompo
                      asyncThunk={
                        memberShip?.some((i) => i?.name === "Admin")
                          ? getClassessAsyncThunk
                          : getStaffClassessAsyncThunk
                      }
                      fetchInitialData={false}
                      componentProps={{ handleSelectClass, selectedSpace }}
                      reducer={"classes"}
                      dataKey={"Classes"}
                      limitArray={[10, 20, 30, 50]}
                      action={
                        memberShip?.some((i) => i?.name === "Admin")
                          ? "getClassessAsyncThunk"
                          : "getStaffClassessAsyncThunk"
                      }
                      Card={ClassesListView}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ClassModal;
