import React from 'react'
import imglog from '../assets/images/place-holder/home icon.svg'
import imglog1 from '../assets/images/place-holder/starsicon.svg'
import imglog2 from '../assets/images/place-holder/paper.svg'
import imglog3 from '../assets/images/place-holder/inbox 2.svg'
import imglog4 from '../assets/images/place-holder/list.svg'
import imglog5 from '../assets/images/place-holder/support.svg'
import imglog6 from '../assets/images/place-holder/account.svg'
import imglog7 from '../assets/images/place-holder/space-img.svg'
import imglog8 from '../assets/images/place-holder/account_box_FILL0_wght400_GRAD0_opsz24 (1) 2.svg'
import imglog9 from '../assets/images/place-holder/profileicon.svg'
import imglog10 from '../assets/images/place-holder/Guardians.svg'
import imglog11 from '../assets/images/place-holder/supervisor.svg'
import imglog12 from '../assets/images/place-holder/ballot_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg'
import imglog13 from '../assets/images/place-holder/4in1icon.svg'
import imglog14 from '../assets/images/place-holder/digital_wellbeing_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg'
import imglog15 from '../assets/images/place-holder/Analytics.svg'
import imglog16 from '../assets/images/place-holder/Insights.svg'
import imglog17 from '../assets/images/place-holder/browse_activity_2.svg'
import imglog18 from '../assets/images/place-holder/Streams.svg'
import imglog19 from '../assets/images/place-holder/Grades.svg'
import imglog20 from '../assets/images/place-holder/Report.svg'
import imglog21 from '../assets/images/place-holder/leaderboard.svg'
import imglog22 from '../assets/images/place-holder/rank.svg'
import imglog23 from '../assets/images/place-holder/wysiwyg.svg'
import imglog24 from '../assets/images/place-holder/calendar.svg'
import imglog25 from '../assets/images/place-holder/select_window_2.svg'
import imglog26 from '../assets/images/place-holder/assignment.svg'
import imglog27 from '../assets/images/place-holder/blocks.svg'
import imglog28 from '../assets/images/place-holder/chat icon.svg'
import imglog29 from '../assets/images/place-holder/edit icon blue.svg'
import { useNavigate } from 'react-router-dom'
import { redirect_uris } from '../constants'

function GuidePage({ currentClass }) {
    const navigate = useNavigate();
    const customToken = localStorage.getItem("custom-token");
    const getCustomToken = () => localStorage.getItem("custom-token")


    return (
        <div>
            <div className='consol-application-page'>
                <div className="admin-dashboard-card-heading">
                    <h2>Applications</h2>
                </div>
                <div className='consol-applications'>
                    <div className="whole-info">
                        <div className="row">
                            <div className="col-4">
                                <div className="heading">
                                    <div className="icon">
                                        <img src={imglog} alt="home icon.svg" className='hedding-icon' />
                                    </div>
                                    <span>Admin</span>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="text">
                                    <span>Product</span>
                                    <span>Docs</span>
                                </div>
                                {/* <div className="info">
                                    <div className="info1" onClick={() => {
                                        navigate("/behaviour");
                                    }}>
                                        <div className="icon">
                                            <img src={imglog1} alt="starsicon.svg" id='icon' />
                                        </div>
                                        <span>Assistant</span>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1" onClick={() => {
                                        navigate("/logs");
                                    }}>
                                        <div className="icon">
                                            <img src={imglog3} alt="starsicon.svg" id='icon' />
                                        </div>
                                        <span>Inbox</span>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div> */}
                                <div className="info">
                                    <div className="info1" >
                                        <div className="icon">
                                            <img src={imglog4} alt="list.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Admin + `roster?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Roster</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1" >
                                        <div className="icon">
                                            <img src={imglog5} alt="support.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Admin + `general?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Setting</span>
                                        </a>


                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog6} alt="account.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Admin + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Dashboard</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1" >
                                        <div className="icon">
                                            <img src={imglog7} alt="starsicon.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Admin + `Space?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Spaces</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="whole-info">
                        <div className="row">
                            <div className="col-4">
                                <div className="heading">
                                    <div className="icon">
                                        <img src={imglog8} alt="haccount_box_FILL0_wght400_GRAD0_opsz24 (1) 2.svg" className='hedding-icon' />
                                    </div>
                                    <span>People</span>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="text">
                                    <span>Product</span>
                                    <span>Docs</span>
                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog9} alt="profileicon.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.People + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Students</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog10} alt="Guardians.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.People + `guardians?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Guardians</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog11} alt="supervisor.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.People + `family?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Family</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                {/* <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog12} alt="ballot_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.People + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Records</span>

                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog13} alt="account.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.People + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Rewards</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog14} alt="digital_wellbeing_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.People + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Reflections</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="whole-info">
                        <div className="row">
                            <div className="col-4">
                                <div className="heading">
                                    <div className="icon">
                                        <img src={imglog15} alt="analytics.svg" className='hedding-icon' />
                                    </div>
                                    <span>Analytics</span>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="text">
                                    <span>Product</span>
                                    <span>Docs</span>
                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog16} alt="Insights.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Analytics + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Insights</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog17} alt="browse_activity_2.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Analytics + `activity?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Activity</span>

                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog18} alt="Streams.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Analytics + `streams?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Streams</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog19} alt="Grades.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Analytics + `grade?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Grades</span>

                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog20} alt="Report.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Analytics + `report?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Report</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                {/* <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog21} alt="leaderboard.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Analytics + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Leaderboard</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div> */}
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog22} alt="rank.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Analytics + `ranks?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Rank</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="whole-info">
                        <div className="row">
                            <div className="col-4">
                                <div className="heading">
                                    <div className="icon">
                                        <img src={imglog23} alt="wysiwyg.svg" className='hedding-icon' />
                                    </div>
                                    <span>Engage</span>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="text">
                                    <span>Product</span>
                                    <span>Docs</span>
                                </div>
                                {/* <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog24} alt="calendar.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Engage + `calendar?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Calendar</span>

                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div> */}
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog25} alt="select_window_2.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Engage + `catalogs?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Catalog</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                {/* <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog26} alt="assignment.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Engage + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Gradebook</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div> */}
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog27} alt="support.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Engage + `applets?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Applets</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                {/* <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog28} alt="chat icon.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Engage + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Channels</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div>
                                <div className="info">
                                    <div className="info1">
                                        <div className="icon">
                                            <img src={imglog29} alt="edit icon blue.svg" id='icon' />
                                        </div>
                                        <a style={{ color: "black" }} href={redirect_uris.Engage + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
                                            target="blank">
                                            <span>Boards</span>
                                        </a>
                                    </div>
                                    <div className="info2">
                                        <img src={imglog2} alt="paper.svg" />
                                    </div>

                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuidePage
