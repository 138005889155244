import React from "react";
import { Link } from "react-router-dom";
import { TbUserSquare } from "react-icons/tb";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import "./Analytics.scss";
import avatar from "../../assets/images/avatars/avatar.png";
import Dropdown from "react-bootstrap/Dropdown";
import { redirect_uris } from "../../constants";
import { getAuth } from "firebase/auth";
import SwitchSpaces from "./switchSpaces";
import { FiMenu } from "react-icons/fi";
import { IoNotifications } from "react-icons/io5";
import { MdOutlineSettings } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { FiLayout } from "react-icons/fi";
import { MdEventNote } from "react-icons/md";
import { MdOutlineAnalytics } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import PermIdentitySharpIcon from "@mui/icons-material/PermIdentitySharp";
import { countUserEvent } from "../../utils/utlis";
import { useSelector } from "react-redux";
import NoDataFound from "../NoDataFound";
import useNotifications from "../../hooks/useNotification";
import logout1 from '../../assets/images/icons/logout.svg'
import supportImge from "../../images/header.svg";

const IlmPortalNavbar = ({
  onPortalChange,
  auth,
  logout,
  loading,
  sidebar = true,
  sidebarUris = [],
  children = null,
}) => {
  const [isIntercomOpen, setIsIntercomOpen] = React.useState(false);
  const { currentUser: user, tenantId } = getAuth();
  console.log("🚀 ~ tenantId:", tenantId);
  const SingleUser = useSelector((state) => state.auth.profile);
  console.log("🚀 ~ SingleUser:", SingleUser)
  const { notifications, readNotification } =
    useNotifications(tenantId, user?.uid);
  console.log("🚀 ~ notifications:", notifications)
  const [sidebarClass, setSidebarClass] = React.useState("");
  const [sidebarClassMobile, setSidebarClassMobile] = React.useState("");
  const currentClass = useSelector((state) => state.auth.class); // Adjust this selector based on your Redux state structure
  const userRole = localStorage.getItem("role");

  const toggleHideClass = () => {
    setSidebarClass((prevClass) =>
      prevClass.includes("active") ? "" : "active"
    );
  };
  const removeActiveClass = () => {
    setSidebarClassMobile("");
  };
  const profileHandler = () => {
    window.open(redirect_uris.Login + "profile/" + user?.uid, "_blank");
  };

  const accountHandler = () => {
    window.open(redirect_uris.Login, "_blank");
  };

  const formatNotificationType = (str) => {
    if (str)
      return str
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    else return str;
  };

  // const handleToggleIntercom = () => {
  //   if (window.Intercom) {
  //     if (isIntercomOpen) {
  //       window.Intercom("hide"); // Close Intercom
  //     } else {
  //       window.Intercom("show"); // Open Intercom
  //     }
  //     setIsIntercomOpen(!isIntercomOpen); // Toggle state
  //   } else {
  //     console.error("Intercom is not initialized.");
  //   }
  // };

  return (
    <>
      <div className="newnav">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col-md-6 col-5 align-self-center">
            <div className="main-section">
              <div className="section-1">
                <div className="menu-icon">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <FiMenu />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="menuItems">
                        <div className="heading-wrapper">
                          <strong>
                            {" "}
                            {document.location.pathname
                              .split("/")[1]
                              .charAt(0)
                              .toUpperCase() +
                              document.location.pathname.split("/")[1].slice(1)}
                          </strong>
                        </div>
                        <div className="mainItems">
                          <div
                            className="items"
                            onClick={() =>
                              onPortalChange &&
                              typeof onPortalChange === "function" &&
                              onPortalChange("Console")
                            }
                          >
                            <div className="icons">
                              <FiLayout />
                            </div>
                            <p>Home</p>
                          </div>

                          <div
                            className="items"
                            // onClick={() =>

                            //   onPortalChange &&
                            //   typeof onPortalChange === "function" &&
                            //   onPortalChange("People")
                            // }

                            onClick={() => {
                              countUserEvent("people", {
                                eventName: "navigate_console_people",
                                currentClass,
                              });
                              if (
                                onPortalChange &&
                                typeof onPortalChange === "function"
                              ) {
                                onPortalChange("People");
                              }
                            }}
                          >
                            <div className="icons">
                              <TbUserSquare />
                            </div>
                            <p>People</p>
                          </div>
                          <div
                            className="items"
                            onClick={() => {
                              countUserEvent("engage", {
                                eventName: "navigate_console_engage",
                                currentClass,
                              });
                              if (
                                onPortalChange &&
                                typeof onPortalChange === "function"
                              ) {
                                onPortalChange("Studio");
                              }
                            }}
                          >
                            <div className="icons">
                              <MdEventNote />
                            </div>
                            <p>Engage</p>
                          </div>

                          <div
                            className="items"
                            onClick={() => {
                              countUserEvent("engage", {
                                eventName: "navigate_console_engage",
                                currentClass,
                              });
                              if (
                                onPortalChange &&
                                typeof onPortalChange === "function"
                              ) {
                                onPortalChange("Analytics");
                              }
                            }}
                          >
                            <div className="icons">
                              <MdOutlineAnalytics />
                            </div>
                            <p>Analytics</p>
                          </div>
                          {userRole === "Admin" && (
                            <div
                              className="items"
                              onClick={() => {
                                // Call countUserEvent for "logout" event
                                countUserEvent("admin", {
                                  eventName: "navigate_console_admin",
                                  currentClass,
                                });

                                // Proceed with onPortalChange logic
                                if (
                                  onPortalChange &&
                                  typeof onPortalChange === "function"
                                ) {
                                  onPortalChange("Admin");
                                }
                              }}
                            >
                              <div className="icons">
                                <MdOutlineAdminPanelSettings />
                              </div>
                              <p>Admin</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <SwitchSpaces />
            </div>
          </div>
          <div className="col-md-6 col-7 align-self-center">
            <div className="icons-section">
            <a href="https://api.whatsapp.com/send/?phone=16122002631&text&type=phone_number&app_absent=0" className="starIcon" 
            // onClick={handleToggleIntercom}
            >
                <img src={supportImge} alt="support icon"/>
              </a>
              {/* <Dropdown direction="end">
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="starIcon">
                    <IoNotifications fontSize={20} />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-end dropdown-menu-avtar">
                  <div className="menuItems">
                    <div className="dropdown-heading">
                      <strong>Notifications</strong>
                    </div>
                    <div className="notificationItmes">
                      {!notifications.filter(
                        (notification) =>
                          notification.status === "unread" ||
                          !notification.status
                      )?.length > 0 ? (
                        <NoDataFound
                          NoDataHeading={"No notifications"}
                          NotFoundImage={<IoNotifications />}
                        />
                      ) : (
                        notifications
                          .filter(
                            (notification) =>
                              notification.status === "unread" ||
                              !notification.status
                          )
                          ?.sort((a, b) => b?.timestamp - a?.timestamp)
                          ?.map((item, i) => {
                            return (
                             <div
                                  className="inner-items"
                                  key={i}
                                  onClick={() => readNotification(item?.id)}
                                >
                                  <div className="inner-icon">
                                    <div className="icon-profile">
                                      <img src={us} alt="us" />
                                    </div>
                                    <div className="text-data">
                                      <strong>
                                        {item?.type
                                      ? formatNotificationType(item?.type)
                                      : "New Notification"}
                                      </strong>
                                      <br />
                                      <p>{item.description}</p>
                                    </div>
                                  </div>
                                  <span>Just Now</span>
                                </div>
                            );
                          })
                      )}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown> */}
              <div className="avtar">
                <Dropdown direction="end">
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50px",
                      }}
                      src={SingleUser?.photoURL || user?.photoURL || avatar}
                      alt="Avtar"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-end dropdown-menu-avtar">
                    <div className="menuItems">
                      {loading ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <div className="dropdown-heading">
                          <img
                            style={{
                              width: "36px",
                              height: "36px",
                              borderRadius: "50px",
                            }}
                            src={SingleUser?.photoURL || user?.photoURL || avatar}
                            alt="Avtar"
                          />
                          <strong>{auth?.currentUser?.displayName}</strong>
                        </div>
                      )}

                      <div className="mainItems">
                        <div
                          className="items"
                          onClick={() => {
                            countUserEvent("profile", {
                              eventName: "navigate_console_profile",
                            });
                            profileHandler();
                          }}
                        >
                          <div className="icons-1">
                            <PermIdentitySharpIcon />
                          </div>

                          <p>Profile</p>
                        </div>
                      </div>

                      <div className="mainItems">
                        <div
                          className="items"
                          onClick={() => {
                            countUserEvent("account", {
                              eventName: "navigate_console_account",
                            });
                            accountHandler();
                          }}
                        >
                          <div className="icons-1">
                            <MdOutlineSettings />
                          </div>

                          <p>Accounts</p>
                        </div>
                      </div>
                      <div className="mainItems">
                        <div className="items">
                          <div className="icons-1">
                            <img src={logout1} alt="logout" />

                            {/* <LuLogOut /> */}
                          </div>
                          <p
                            // onClick={logout}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              countUserEvent("logout", {
                                eventName: "create_ilmiya_console_account",
                              });
                              logout();
                            }}
                          >
                            Logout
                          </p>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dot-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                  >
                    <rect
                      x="0.902344"
                      y="0.902344"
                      width="7.2"
                      height="7.2"
                      rx="3.6"
                      fill="#00AB1B"
                      stroke="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="allStudentsStreamPage">
        <div
          id="overLay"
          className={sidebarClassMobile}
          onClick={removeActiveClass}
        ></div>
        {sidebar ? (
          <div className="main-dashboard-wrapper">
            <div
              id="side-bar-wrapper"
              className={`${sidebarClass} ${sidebarClassMobile}`}
            >
              <div className="side-bar-title-wrapper">
                <Link to="/">
                  <img
                    src={require("./analyticsIcon.png")}
                    alt="analyticsIcon"
                  />
                  <strong> {document.location.pathname.split("/")[1]}</strong>
                </Link>
              </div>
              <div className="sidebar-menu-wrapper">
                <ul className="sidebar-menu">
                  {sidebarUris.map((uri, index) => (
                    <li key={index}>
                      <Link to={uri.path}>
                        {uri.icon}
                        <span className="span">{uri.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="close-icon-wrapper">
                <a
                  href="#"
                  className="sidebar-toggle-close"
                  onClick={toggleHideClass}
                >
                  <img
                    src={require("./sideBarColorIcon.png")}
                    alt="sideBarColorIcon"
                  />
                </a>
              </div>
            </div>
            <div id="main-page-body-wrapper" className={sidebarClass}>
              <div className="container-fluid">{children}</div>
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </>
  );
};

export default IlmPortalNavbar;
