import React, { useEffect, useState } from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { RiShareBoxLine } from "react-icons/ri";
import batch1 from '../assets/images/icons/batch-1.png';
import batch2 from '../assets/images/icons/batch-2.png';
import batch3 from '../assets/images/icons/batch-3.png';
import batch4 from '../assets/images/icons/batch-4.png';
// import crown1 from "../../images/new-images/icons/crown-1.png";
import crown1 from '../assets/images/icons/crown-1.png';
import crown2 from '../assets/images/icons/crown-2.png';
import crown3 from '../assets/images/icons/crown-3.png';
import crown4 from '../assets/images/icons/crown-4.png';
import trophy1 from '../assets/images/icons/trophy-1.png';
import trophy2 from '../assets/images/icons/trophy-2.png';
import trophy3 from '../assets/images/icons/trophy-3.png';
import trophy4 from '../assets/images/icons/trophy-4.png';
import { MdKeyboardArrowDown } from "react-icons/md";
import img from '../assets/images/icons/highlight-img.svg'
import QuestionColumn from './QuestionColumn';
import Chart from "./BarChart";
import BarChart from './BarChart';
import { ApiRequests } from '../service/ApiRequests';
import NoDataFound from './NoDataFound';
import { Link } from 'react-router-dom';
import { redirect_uris } from '../constants';
// import Chart from "./BarChart";


function HighlightsPage({ currentClass }) {
  const [activityData, setActivityData] = useState([]);
  const [getUsers, setGetUsers] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [TrophiesCount, setTrophiesCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState("monthly");
  const [selectionRange, setSelectionRange] = useState({
    key: "selection",
  });
  const customToken = localStorage.getItem("custom-token");
  const getCustomToken = () => localStorage.getItem("custom-token")
  const [data, setData] = useState([]);
  const getActivityData = async () => {
    const response = await ApiRequests.getActivityData({ period });
    // ApiRequests.getActivity({limit:1000, startDate: "2024-12-12"});
    // ApiRequests.getActivityData({
    //   ...params, period: "yearly"
    // });
    setActivityData(response.data);
    console.log(response.data);
  };
  useEffect(() => {
    getActivityData();
  }, [period]);

  const getUsersWithGrade = async () => {
    try {
      setLoading(true);
      const res = await ApiRequests.getSnapshot_rank_top_three();
      setGetUsers(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUsersWithGrade();
  }, []);

  const getAnalysisCount = async () => {
    try {
      setLoading(true);
      const res = await ApiRequests.getMasteryCount();
      setAnalysis(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnalysisCount();
  }, []);

  const getAnalysisPercentage = (mastery) => {
    return Object.values(analysis).length
      ? Object.values(analysis).find((item) => item?.mastery === mastery)
        ?.percentage || 0
      : 0;
  };

  const totalTrophies = Object.values(TrophiesCount).reduce(
    (sum, count) => sum + count,
    0
  );

  const topRankedUsers = getUsers?.slice(0, 3);

  function formatTime(seconds) {
    const totalSeconds = Math.floor(seconds);
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  }

  const getTrophiesCount = async () => {
    try {
      setLoading(true);
      const res = await ApiRequests.getTrophiesCount();
      setTrophiesCount(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrophiesCount();
  }, []);


  const getData = async () => {
    try {
      setLoading(true);
      const res = await ApiRequests.getOverview({ ...selectionRange });
      setData(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [selectionRange]);


  return (
    <div className='highlight-container'>
      <div className='highlight-header'>
        <h1>Insights</h1>
      </div>
      <QuestionColumn data={data} totalTrophies={totalTrophies} />
      <div className="activity-page">
        <Row>
          <Col md={6} className="mb-md-4 mb-2">
            <div className="card-div">
              <div className="student-activity-1">
                <h5> Students Activity</h5>
                <div>
                  <Dropdown onSelect={(e) => setPeriod(e)}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{
                        backgroundColor: "#FFFFFF",
                        textTransform: "capitalize",
                        border: " 1px solid #DEE1E6",
                        display: "flex",
                        alignItems: "center",
                        color: "#2D2F31",
                      }}
                    >
                      {period}
                      <MdKeyboardArrowDown />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey={"daily"}>Daily</Dropdown.Item>
                      {/* <Dropdown.Item eventKey={'weekly'} >Weekly</Dropdown.Item> */}
                      <Dropdown.Item eventKey={"monthly"}>Monthly</Dropdown.Item>
                      <Dropdown.Item eventKey={"yearly"}>Yearly</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="main-chart">
                {activityData?.questionsAnsweredData?.length ? (
                  <Chart data={activityData.questionsAnsweredData} />
                ) : (
                  <NoDataFound message="No data found" colspan={4} />
                )}
              </div>
            </div>
          </Col>

          <Col md={6} className="mb-2 mb-md-4">
            <div className="card-div">
              <div className="student-activity">
                <h5>Top Students</h5>
                <a style={{ color: "black" }} href={redirect_uris.Analytics + `ranks?space_id=${currentClass?.class_id}&token=${getCustomToken()}`} blank="_blank">
                  <RiShareBoxLine />
                </a>
              </div>
              <div className="main-table">
                {getUsers?.length === 0 ? (
                  <NoDataFound message="No data found" colspan={4} />
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th style={{ textAlign: "left" }}>Name</th>
                        <th>Points</th>
                        <th>Time spent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {topRankedUsers?.map((item, index) => (
                          <tr key={item?.User?.id || index}>
                            <td>
                              <div className="main-batch">
                                <img
                                  src={
                                    index === 0
                                      ? batch1
                                      : index === 1
                                        ? batch2
                                        : index === 2
                                          ? batch3
                                          : batch4 // Default image for other ranks
                                  }
                                  alt="Rank Badge"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="batch">
                                <img
                                  className={"userImage-insights"}
                                  src={item?.User?.photoURL}
                                  alt="User Avatar"
                                />
                                <p style={{ whiteSpace: "nowrap" }}>
                                  {item?.User?.displayName || "Unknown User"}
                                </p>
                              </div>
                            </td>
                            <td>{item?.totalObtainedMarks || 0}</td>
                            <td>{formatTime(item?.totalTimeTaken || 0)}</td>
                          </tr>
                        ))}
                      </>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </Col>

          <Col md={6} className="mb-2 mb-md-4">
            <div className="card-div">
              <div className="student-activity">
                <h5>Mastery</h5>
                <Link to={redirect_uris.Analytics + `report?space_id=${currentClass?.class_id}&token=${getCustomToken()}`} style={{ color: "black" }}>
                  {" "}
                  <RiShareBoxLine />
                </Link>
              </div>
              <div className="mastery">
                <div className="main-crown">
                  <div className="crown">
                    <img src={crown1} alt="img" />
                  </div>
                  <h4>{getAnalysisPercentage("Beginner")}%</h4>
                  <p>Beginner</p>
                </div>
                <div className="main-crown">
                  <div className="crown">
                    <img src={crown2} alt="img" />
                  </div>
                  <h4>{getAnalysisPercentage("Competent")}%</h4>
                  <p>Competent</p>
                </div>
                <div className="main-crown">
                  <div className="crown">
                    <img src={crown3} alt="img" />
                  </div>
                  <h4>{getAnalysisPercentage("Proficient")}%</h4>
                  <p>Proficient</p>
                </div>
                <div className="main-crown">
                  <div className="crown">
                    <img src={crown4} alt="img" />
                  </div>
                  <h4>{getAnalysisPercentage("Expert")}%</h4>
                  <p>Expert</p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="mb-2 mb-md-4">
            <div className="card-div">
              <div className="student-activity">
                <h5>Trophies Earned | {totalTrophies}</h5>
                <a style={{ color: "black" }} href={redirect_uris.Analytics + `report?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}>
                  <RiShareBoxLine />
                </a>
              </div>
              <div className="mastery">
                <div className="trophy-crown">
                  <div className="crown">
                    <img src={trophy1} alt="img" />
                  </div>
                  <h4>{TrophiesCount?.platinum}</h4>
                  <p>Platinum</p>
                </div>
                <div className="trophy-crown">
                  <div className="crown">
                    <img src={trophy2} alt="img" />
                  </div>
                  <h4>{TrophiesCount?.gold}</h4>
                  <p>Gold</p>
                </div>
                <div className="trophy-crown">
                  <div className="crown">
                    <img src={trophy3} alt="img" />
                  </div>
                  <h4>{TrophiesCount?.bronze}</h4>
                  <p>Bronze</p>
                </div>
                <div className="trophy-crown">
                  <div className="crown">
                    <img src={trophy4} alt="img" />
                  </div>
                  <h4>{TrophiesCount?.silver}</h4>
                  <p>Silver</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default HighlightsPage;
