import React, { useEffect, useState } from 'react'
import people from '../assets/images/icons/people.svg'
import guardian from '../assets/images/icons/Guardians.svg'
import clock from '../assets/images/icons/clock-loader.svg'
import circle from '../assets/images/icons/console-circle.svg'
import { useDispatch, useSelector } from 'react-redux'
import { get, onChildAdded, ref } from 'firebase/database'
import { db } from '../config/firebase'
import { organization, redirect_uris } from '../constants'
import { ApiRequests } from '../service/ApiRequests'
import { validateClassAsyncThunk } from '../redux/pagesSlices/userSlice'

function ConsoleHeader({ currentClass }) {
   const [data, setData] = useState([]);
   const [users, setUsers] = useState();
   const [loading, setLoading] = useState(true);
   const customToken = localStorage.getItem("custom-token");
   const [filteredData, setFilteredData] = useState([]);
   const d = useDispatch();
   const getCustomToken = () => localStorage.getItem("custom-token")
   const [selectionRange, setSelectionRange] = useState({
      key: "selection",
   });
   const getData = async () => {
      try {
         setLoading(true);
         const res = await ApiRequests.getOverview({ ...selectionRange });
         setData(res.data);
      } catch (error) {
         console.log(error);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      getData();
   }, [selectionRange]);
   const org = organization();
   useEffect(() => {
      const socket = new WebSocket(
        `wss://${process.env.REACT_APP_StreamPath}/websocket?org=${org.tenantId}&role=teacher`
      );
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "onlineUsers") {
          setUsers(data.users);
        } else if (data.type === "update") {
          setUsers(data.users);
        }
      };
  
      socket.onopen = () => console.log("Connected as Teacher");
      socket.onclose = () => console.log("Disconnected from WebSocket");
  
      return () => socket.close();
    }, [org.tenantId]);

    useEffect(() => {
      const filteredResults = users
        ?.filter((user) => user?.status === "online") || []; // Remove duplicates
      if (filteredResults?.length) {
        d(
          validateClassAsyncThunk({
            data: { user_ids: filteredResults.map(i => i.userId) },
            callBack: (data) => {
              console.log("callback", data);
              setFilteredData(filteredResults.filter(i => data.some(j => j.user_id === i.userId)));
            },
          })
        );
      } else {
        setFilteredData([])
      }
    }, [users, currentClass?.class_id]);



   return (
      <div className='console-header-icons'>
         <a style={{ color: "black" }} href={redirect_uris.People + `?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
            target="blank">
            <div className="icon1">
               <img src={clock} alt="image" />
               <span>{data?.totalStudents || 0}</span>
            </div>
         </a>
         <a style={{ color: "black" }} href={redirect_uris.People + `guardians?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
            target="blank">
            <div className="icon1">
               <img src={guardian} alt="image" className='guardian' />
               <span>{data?.totalGuardian || 0}</span>
            </div>
         </a>
         <a style={{ color: "black" }} href={redirect_uris.People + `family?space_id=${currentClass?.class_id}&token=${getCustomToken()}`}
            target='blank'>
            <div className="icon1">
               <img src={people} alt="image" />
               <span>{data?.totalFamily || 0}</span>
            </div>
         </a>
         <div className="icon2">
            <img src={circle} alt="image" />
            <span>{filteredData ? filteredData.length : 0}</span>
         </div>
      </div>
   )
}

export default ConsoleHeader
